<template>
    <modal ref="modalCodigoVerificacion" icon="login (2)" titulo="Código Seguridad" :no-cancelar="true" :no-aceptar="true" :btns="buttons" @accion="confirmCode">
        <div class="row mx-0 justify-center">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0 justify-center">
                    <div class="row mx-0 my-2 text-center text-muted2">
                        Introduce el código que se te envió al correo electrónico o al número de teléfono registrado.
                    </div>
                    <div class="col px-0 mx-1">
                        <el-input
                        ref="cod1"
                        v-model.number="cod1"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(2)"
                        />
                    </div>
                    <div class="col px-0 mx-1">
                        <el-input
                        ref="cod2"
                        v-model.number="cod2"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(3)"
                        @keyup.native.delete="back(1)"
                        />
                    </div>
                    <div class="col px-0 mx-1">
                        <el-input
                        ref="cod3"
                        v-model.number="cod3"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(4)"
                        @keyup.native.delete="back(2)"
                        />
                    </div>
                    <div class="col px-0 mx-1">
                        <el-input
                        ref="cod4"
                        v-model.number="cod4"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(5)"
                        @keyup.native.delete="back(3)"
                        />
                    </div>
                    <div class="col px-0 mx-1">
                        <el-input
                        ref="cod5"
                        v-model.number="cod5"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(6)"
                        @keyup.native.delete="back(4)"
                        />
                    </div>
                    <div class="col px-0 mx-1">
                        <el-input
                        ref="cod6"
                        v-model.number="cod6"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @keyup.native.delete="back(5)"
                        />
                    </div>
                </div>
                <div class="row justify-center ">
                    <div class="col f-15" style="color:#0892DD;" @click="resendCode">
                        <p class="text-center cr-pointer">
                            <template v-if="!timeOut">
                                Volver a enviar el código
                            </template>
                            <template v-else>
                                {{ segundos }} segundos
                            </template>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import authService from '~/services/auth';
import registroService from '~/services/registro/registro'
const segundosDefault = 90
export default {
    data(){
        return{
            cod1: '',
            cod2: '',
            cod3: '',
            cod4: '',
            cod5: '',
            cod6: '',
            buttons: [
                {
                    texto: 'Confirmar Código',
                    color: 'bg-leeche br-12 px-4 text-white'
                }
            ],
            isTienda: false,
            celular: false,
            timer: null,
            interval: null,
            timeOut: false,
            segundos: segundosDefault
        }
    },
    computed: {
        allCode(){
            return `${this.cod1}${this.cod2}${this.cod3}${this.cod4}${this.cod5}${this.cod6}`
        },
        codeComplete(){
            return [this.cod1,this.cod2, this.cod3, this.cod4, this.cod5, this.cod6].every((el) => {
                return !_.isEmpty(`${el}`.trim())
            })
        },
        idUser(){
            return this.$store.getters['register/idUser']
        },
        indicativo(){
            return this.$store.getters['register/getIndicativo']
        }
    },
    methods: {
        toggle(isTienda = false, celular = false){
            this.isTienda = isTienda
            this.celular = celular
            this.$refs.modalCodigoVerificacion.toggle();
        },
        async confirmCode(){
            try {
                if (this.codeComplete){
                    const p =  await registroService.verificarCode(this.allCode)

                    console.log({p});
                    /* const obj = {
                        "id": this.idUser,
                        "codigo": this.allCode
                    }
                    const { data } = await authService.checkCodigoTemporal(obj)
                    if(data.data == 'exito'){
                        if (this.isTienda){
                            this.loginTienda(data.token, data.usuario)
                        } else{
                            this.redireccion()

                        }
                    } */
                }
            } catch (e){
                switch (e.code){
                case 'auth/invalid-verification-code':
                    this.notificacion('Error', 'El código que introdujo no es el correcto', 'error')
                    break;

                default:
                    this.error_catch(e)

                }
            }
        },
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },
        async resendCode(){
            try {
                if (this.interval || this.timer){
                    return
                }
                this.interval = setInterval(() => {
                    this.segundos--;
                }, 1000)
                this.timer = setTimeout(() => {
                    this.timeOut = false
                    this.segundos = segundosDefault
                    clearTimeout(this.timer)
                    clearInterval(this.interval)
                    this.timer = null
                    this.interval = null
                }, segundosDefault * 1000);

                this.timeOut = true
                let params = {
                    "usuario": this.idUser,
                    "indicativo": this.indicativo
                }
                const { data } = await authService.reenviarCodigoTemporalRegistro(params)

            } catch (e){
                this.error_catch(e)
            }
        },
        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },
        async loginTienda(token, usuario){
            this.$store.commit('auth/ALMACENAR_TOKEN', token)
            this.$store.commit('auth/ALMACENAR_USUARIO', usuario)
            await this.$store.dispatch('auth/consultarRoles')
            this.$refs.modalCodigoVerificacion.toggle();

            this.$router.push({ name: 'registrado' })

        },
        redireccion(){
            const name = 'crear.perfil.cliente'
            this.$refs.modalCodigoVerificacion.toggle();
            this.$router.push({ name })
        }
    }
}
</script>

<style lang="scss" scoped>
.resend-button{
    background-color: #F5F5F5;
    color: #5D5D5D;
    font-size: 18px;
    border-radius: 12px;
}
.br-12{
    border-radius: 12px !important;
}
</style>
